import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert } from '../../components/common/Alert/Alert';

import { Button } from '../../components/common/Button/Button';
import { Text } from '../../components/Text/Text';
import { countriesList } from '../../shared/countrylist';

export const PhoneNumberForm = ({
  onSubmitLoginForm, phoneNumber, onChangePhoneNumber, onChangeCountryCode, status,
}) => {
  const { t } = useTranslation('application');

  const defaultCountry = countriesList.find((country) => country.value === 'Saudi Arabia');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCountryClick = (countryValue) => {
    setSelectedCountry(countryValue);
    onChangeCountryCode(countryValue.dialCode);
    setIsDropdownOpen(false);
    toggleDropdown();
  };

  return (
    <>
      <Text type="headline2" className="login__header">{t('login.headerText')}</Text>
      <form onSubmit={onSubmitLoginForm}>
        <div className="login__form-element">
          <div className="login__input-container">
            <div className="country-code-container">
              <button type="button" className="login__input" onClick={toggleDropdown}>
                {selectedCountry.dialCode}
              </button>
              {isDropdownOpen && (
                <div className="country-code-dropdown">
                  {countriesList.map((eachCountry, index) => (
                    <div className="each-code-container">
                      <button key={index} type="button" className="country-code-button" onClick={() => handleCountryClick(eachCountry)}>
                        {eachCountry.dialCode}
                      </button>
                      <img src={eachCountry.flag} alt={eachCountry.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <input
              className="login__input phone-number"
              type="tel"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChangePhoneNumber}
              placeholder="0000 0000"
              dir="ltr"
              inputMode="tel"
              maxLength={12}
            />
          </div>
        </div>
        <div className="login__form-element">
          <Text type="body1">
            {t('login.agreementTextPartOne')}
            <br />
            <Link to="/policy">{t('login.agreementLink')}</Link>
            <br />
            {t('login.agreementTextPartTwo')}
          </Text>
        </div>
        <div className="login__form-element">
          <Button buttonType="submit" text={t('login.signIn')} type="primary" />
        </div>
      </form>
      {status && (
      <Alert type={status.type}>{t('login.phoneError')}</Alert>
      )}
    </>
  );
};
