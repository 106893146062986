export const locationsSchemaData = {
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Camel Step | خطوة جمل',
  telephone: '+966 9200 00689',
  url: 'https://camelstep.com/',
  department: [
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'HADE2725، 2725، 9495 البحيرة 31',
        addressLocality: 'Hail',
        postalCode: '55422',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Abdullatif bin Abdulmalik Al Shaikh, حي، الملقا, Al Malqa',
        addressLocality: 'Riyadh',
        postalCode: '13521',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Abdulrahman aldakhel street',
        addressLocality: 'Jeddah',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Salaheddin Al Ayoubi St',
        addressLocality: 'Al Khobar',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Ibrahim Al Khalil, Ash Shubaikah, Mecca 24231, C2, Jabal Omar',
        addressLocality: 'Mecca',
        postalCode: '24231',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Imam Saud Bin Abdulaziz Bin Mohammed Rd, An Nakheelh',
        addressLocality: 'Riyadh',
        postalCode: '12382',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'King Saud Road, Muftaha',
        addressLocality: 'Abha',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '73B St - Al Satwa',
        addressLocality: 'Dubai',
        addressCountry: 'United Arab Emirates',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'King Khalid International Airport',
        addressLocality: 'Riyadh',
        postalCode: '13449',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'QM3R+7CP Al Mursalat',
        addressLocality: 'Riyadh',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Eastern Ring Branch Rd, Ghirnatah',
        addressLocality: 'Riyadh',
        postalCode: '13241',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Abi Bakr As Siddiq Rd , حي التعاون',
        addressLocality: 'Riyadh',
        postalCode: '12475',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Al Urubah Rd, Ar Rahmaniyyah',
        addressLocality: 'Riyadh',
        postalCode: '12341',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '84M5+3J',
        addressLocality: 'Dhahran',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Prince Mohammed Ibn Salman Ibn Abdulaziz Rd',
        addressLocality: 'Riyadh',
        addressCountry: 'Saudi Arabia',
      },
    },
    {
      '@type': 'LocalBusiness',
      name: 'Camel Step | خطوة جمل',
      telephone: '+966 9200 00689',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'قصر الحكم 12411',
        addressLocality: 'Riyadh',
        addressCountry: 'Saudi Arabia',
      },
    },

  ],
};
