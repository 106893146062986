export const countriesList = [
  {
    label: 'Saudi Arabia',
    dialCode: '+966',
    isoCode: 'SA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sa.svg',
    value: 'Saudi Arabia',
    translationName: {
      en: 'Saudi Arabia',
      ar: 'المملكة العربية السعودية',
    },
  },
  {
    label: 'Kuwait',
    dialCode: '+965',
    isoCode: 'KW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kw.svg',
    value: 'Kuwait',
    translationName: {
      en: 'Kuwait',
      ar: 'الكويت',
    },
  },
  {
    label: 'Oman',
    dialCode: '+968',
    isoCode: 'OM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/om.svg',
    value: 'Oman',
    translationName: {
      en: 'Oman',
      ar: 'عمان',
    },
  },
  {
    label: 'United Arab Emirates',
    dialCode: '+971',
    isoCode: 'AE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ae.svg',
    value: 'United Arab Emirates',
    translationName: {
      en: 'United Arab Emirates',
      ar: 'الإمارات العربية المتحدة',
    },
  },
  {
    label: 'Bahrain',
    dialCode: '+973',
    isoCode: 'BH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bh.svg',
    value: 'Bahrain',
    translationName: {
      en: 'Bahrain',
      ar: 'البحرين',
    },
  },
  {
    label: 'Qatar',
    dialCode: '+974',
    isoCode: 'QA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/qa.svg',
    value: 'Qatar',
    translationName: {
      en: 'Qatar',
      ar: 'قطر',
    },
  },
  {
    label: 'Canada',
    dialCode: '+1',
    isoCode: 'CA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ca.svg',
    value: 'Canada',
    translationName: {
      en: 'Canada',
      ar: 'كندا',
    },
  },
  {
    label: 'United States',
    dialCode: '+1',
    isoCode: 'US',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/us.svg',
    value: 'United States of America',
    translationName: {
      en: 'United States',
      ar: 'الولايات المتحدة',
    },
  },
  {
    label: 'Bahamas',
    dialCode: '+1242',
    isoCode: 'BS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bs.svg',
    value: 'Bahamas',
    translationName: {
      en: 'Bahamas',
      ar: 'جزر بهاما',
    },
  },
  {
    label: 'Barbados',
    dialCode: '+1246',
    isoCode: 'BB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bb.svg',
    value: 'Barbados',
    translationName: {
      en: 'Barbados',
      ar: 'باربادوس',
    },
  },
  {
    label: 'Anguilla',
    dialCode: '+1264',
    isoCode: 'AI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ai.svg',
    value: 'Anguilla',
    translationName: {
      en: 'Anguilla',
      ar: 'أنغيلا',
    },
  },
  {
    label: 'Antigua and Barbuda',
    dialCode: '+1268',
    isoCode: 'AG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ag.svg',
    value: 'Antigua and Barbuda',
    translationName: {
      en: 'Antigua and Barbuda',
      ar: 'أنتيغوا وبربودا',
    },
  },
  {
    label: 'British Virgin Islands',
    dialCode: '+1284',
    isoCode: 'VG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vg.svg',
    value: 'Virgin Islands (British)',
    translationName: {
      en: 'British Virgin Islands',
      ar: 'جزر فيرجن البريطانية ',
    },
  },
  {
    label: 'United States Virgin Islands',
    dialCode: '+1340',
    isoCode: 'VI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vi.svg',
    value: 'Virgin Islands (U.S.)',
    translationName: {
      en: 'United States Virgin Islands',
      ar: 'جزر فيرجن التابعة للولايات المتحدة',
    },
  },
  {
    label: 'Cayman Islands',
    dialCode: '+1345',
    isoCode: 'KY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ky.svg',
    value: 'Cayman Islands',
    translationName: {
      en: 'Cayman Islands',
      ar: 'جزر كايمان ',
    },
  },
  {
    label: 'Bermuda',
    dialCode: '+1441',
    isoCode: 'BM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bm.svg',
    value: 'Bermuda',
    translationName: {
      en: 'Bermuda',
      ar: 'برمودا',
    },
  },
  {
    label: 'Grenada',
    dialCode: '+1473',
    isoCode: 'GD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gd.svg',
    value: 'Grenada',
    translationName: {
      en: 'Grenada',
      ar: 'غرينادا',
    },
  },
  {
    label: 'Turks and Caicos Islands',
    dialCode: '+1649',
    isoCode: 'TC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tc.svg',
    value: 'Turks and Caicos Islands',
    translationName: {
      en: 'Turks and Caicos Islands',
      ar: 'جزر تركس وكايكوس',
    },
  },
  {
    label: 'Montserrat',
    dialCode: '+1664',
    isoCode: 'MS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ms.svg',
    value: 'Montserrat',
    translationName: {
      en: 'Montserrat',
      ar: 'مونتسيرات',
    },
  },
  {
    label: 'Northern Mariana Islands',
    dialCode: '+1670',
    isoCode: 'MP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mp.svg',
    value: 'Northern Mariana Islands',
    translationName: {
      en: 'Northern Mariana Islands',
      ar: 'جزر ماريانا الشمالية',
    },
  },
  {
    label: 'Guam',
    dialCode: '+1671',
    isoCode: 'GU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gu.svg',
    value: 'Guam',
    translationName: {
      en: 'Guam',
      ar: 'غوام',
    },
  },
  {
    label: 'American Samoa',
    dialCode: '+1684',
    isoCode: 'AS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/as.svg',
    value: 'American Samoa',
    translationName: {
      en: 'American Samoa',
      ar: 'ساموا الأمريكية',
    },
  },
  {
    label: 'Sint Maarten',
    dialCode: '+1721',
    isoCode: 'SX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sx.svg',
    value: 'Sint Maarten (Dutch part)',
    translationName: {
      en: 'Sint Maarten',
      ar: 'سينت مارتن',
    },
  },
  {
    label: 'Saint Lucia',
    dialCode: '+1758',
    isoCode: 'LC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lc.svg',
    value: 'Saint Lucia',
    translationName: {
      en: 'Saint Lucia',
      ar: 'سانت لوسيا',
    },
  },
  {
    label: 'Dominica',
    dialCode: '+1767',
    isoCode: 'DM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dm.svg',
    value: 'Dominica',
    translationName: {
      en: 'Dominica',
      ar: 'دومينيكا',
    },
  },
  {
    label: 'Saint Vincent and the Grenadines',
    dialCode: '+1784',
    isoCode: 'VC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vc.svg',
    value: 'Saint Vincent and the Grenadines',
    translationName: {
      en: 'Saint Vincent and the Grenadines',
      ar: 'سانت فنسنت وجزر غرينادين',
    },
  },
  {
    label: 'Dominican Republic',
    dialCode: '+1849',
    isoCode: 'DO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/do.svg',
    value: 'Dominican Republic',
    translationName: {
      en: 'Dominican Republic',
      ar: 'جمهورية الدمينيكان',
    },
  },
  {
    label: 'Trinidad and Tobago',
    dialCode: '+1868',
    isoCode: 'TT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tt.svg',
    value: 'Trinidad and Tobago',
    translationName: {
      en: 'Trinidad and Tobago',
      ar: 'ترينداد وتوباغو',
    },
  },
  {
    label: 'Saint Kitts and Nevis',
    dialCode: '+1869',
    isoCode: 'KN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kn.svg',
    value: 'Saint Kitts and Nevis',
    translationName: {
      en: 'Saint Kitts and Nevis',
      ar: 'سانت كيتس ونيفيس',
    },
  },
  {
    label: 'Jamaica',
    dialCode: '+1876',
    isoCode: 'JM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jm.svg',
    value: 'Jamaica',
    translationName: {
      en: 'Jamaica',
      ar: 'جامايكا',
    },
  },
  {
    label: 'Puerto Rico',
    dialCode: '+1939',
    isoCode: 'PR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pr.svg',
    value: 'Puerto Rico',
    translationName: {
      en: 'Puerto Rico',
      ar: 'بورتوريكو',
    },
  },
  {
    label: 'Egypt',
    dialCode: '+20',
    isoCode: 'EG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/eg.svg',
    value: 'Egypt',
    translationName: {
      en: 'Egypt',
      ar: 'مصر',
    },
  },
  {
    label: 'Morocco',
    dialCode: '+212',
    isoCode: 'MA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ma.svg',
    value: 'Morocco',
    translationName: {
      en: 'Morocco',
      ar: 'المغرب',
    },
  },
  {
    label: 'Algeria',
    dialCode: '+213',
    isoCode: 'DZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dz.svg',
    value: 'Algeria',
    translationName: {
      en: 'Algeria',
      ar: 'الجزائر',
    },
  },
  {
    label: 'Tunisia',
    dialCode: '+216',
    isoCode: 'TN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tn.svg',
    value: 'Tunisia',
    translationName: {
      en: 'Tunisia',
      ar: 'تونس',
    },
  },
  {
    label: 'Libya',
    dialCode: '+218',
    isoCode: 'LY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ly.svg',
    value: 'Libya',
    translationName: {
      en: 'Libya',
      ar: 'ليبيا',
    },
  },
  {
    label: 'Gambia',
    dialCode: '+220',
    isoCode: 'GM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gm.svg',
    value: 'Gambia',
    translationName: {
      en: 'Gambia',
      ar: 'غامبيا',
    },
  },
  {
    label: 'Senegal',
    dialCode: '+221',
    isoCode: 'SN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sn.svg',
    value: 'Senegal',
    translationName: {
      en: 'Senegal',
      ar: 'السنغال',
    },
  },
  {
    label: 'Mauritania',
    dialCode: '+222',
    isoCode: 'MR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mr.svg',
    value: 'Mauritania',
    translationName: {
      en: 'Mauritania',
      ar: 'مورتانيا',
    },
  },
  {
    label: 'Mali',
    dialCode: '+223',
    isoCode: 'ML',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ml.svg',
    value: 'Mali',
    translationName: {
      en: 'Mali',
      ar: 'مالي',
    },
  },
  {
    label: 'Guinea',
    dialCode: '+224',
    isoCode: 'GN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gn.svg',
    value: 'Guinea',
    translationName: {
      en: 'Guinea',
      ar: 'غينيا',
    },
  },
  {
    label: 'Ivory Coast',
    dialCode: '+225',
    isoCode: 'CI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ci.svg',
    value: 'Ivory Coast',
    translationName: {
      en: 'Ivory Coast',
      ar: 'ساحل العاج',
    },
  },
  {
    label: 'Burkina Faso',
    dialCode: '+226',
    isoCode: 'BF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bf.svg',
    value: 'Burkina Faso',
    translationName: {
      en: 'Burkina Faso',
      ar: 'بوركينا فاسو ',
    },
  },
  {
    label: 'Niger',
    dialCode: '+227',
    isoCode: 'NE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ne.svg',
    value: 'Niger',
    translationName: {
      en: 'Niger',
      ar: 'النيجر',
    },
  },
  {
    label: 'Togo',
    dialCode: '+228',
    isoCode: 'TG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tg.svg',
    value: 'Togo',
    translationName: {
      en: 'Togo',
      ar: 'توغو',
    },
  },
  {
    label: 'Benin',
    dialCode: '+229',
    isoCode: 'BJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bj.svg',
    value: 'Benin',
    translationName: {
      en: 'Benin',
      ar: 'بنين',
    },
  },
  {
    label: 'Mauritius',
    dialCode: '+230',
    isoCode: 'MU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mu.svg',
    value: 'Mauritius',
    translationName: {
      en: 'Mauritius',
      ar: 'موريشيوس',
    },
  },
  {
    label: 'Liberia',
    dialCode: '+231',
    isoCode: 'LR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lr.svg',
    value: 'Liberia',
    translationName: {
      en: 'Liberia',
      ar: 'ليبيريا',
    },
  },
  {
    label: 'Sierra Leone',
    dialCode: '+232',
    isoCode: 'SL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sl.svg',
    value: 'Sierra Leone',
    translationName: {
      en: 'Sierra Leone',
      ar: 'سيراليون',
    },
  },
  {
    label: 'Ghana',
    dialCode: '+233',
    isoCode: 'GH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gh.svg',
    value: 'Ghana',
    translationName: {
      en: 'Ghana',
      ar: 'غانا',
    },
  },
  {
    label: 'Nigeria',
    dialCode: '+234',
    isoCode: 'NG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ng.svg',
    value: 'Nigeria',
    translationName: {
      en: 'Nigeria',
      ar: 'نيجيريا',
    },
  },
  {
    label: 'Chad',
    dialCode: '+235',
    isoCode: 'TD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/td.svg',
    value: 'Chad',
    translationName: {
      en: 'Chad',
      ar: 'تشاد',
    },
  },
  {
    label: 'Cameroon',
    dialCode: '+237',
    isoCode: 'CM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cm.svg',
    value: 'Cameroon',
    translationName: {
      en: 'Cameroon',
      ar: 'الكاميرون',
    },
  },
  {
    label: 'Cape Verde',
    dialCode: '+238',
    isoCode: 'CV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cv.svg',
    value: 'Cape Verde',
    translationName: {
      en: 'Cape Verde',
      ar: 'كاب فيردي',
    },
  },
  {
    label: 'São Tomé and Príncipe',
    dialCode: '+239',
    isoCode: 'ST',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/st.svg',
    value: 'Sao Tome and Principe',
    translationName: {
      en: 'São Tomé and Príncipe',
      ar: 'ساو تومي وبرينسيبي',
    },
  },
  {
    label: 'Equatorial Guinea',
    dialCode: '+240',
    isoCode: 'GQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gq.svg',
    value: 'Equatorial Guinea',
    translationName: {
      en: 'Equatorial Guinea',
      ar: 'غينيا الاستوائية',
    },
  },
  {
    label: 'Gabon',
    dialCode: '+241',
    isoCode: 'GA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ga.svg',
    value: 'Gabon',
    translationName: {
      en: 'Gabon',
      ar: 'غابون',
    },
  },
  {
    label: 'Angola',
    dialCode: '+244',
    isoCode: 'AO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ao.svg',
    value: 'Angola',
    translationName: {
      en: 'Angola',
      ar: 'أنغولا',
    },
  },
  {
    label: 'Guinea-Bissau',
    dialCode: '+245',
    isoCode: 'GW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gw.svg',
    value: 'Guinea-Bissau',
    translationName: {
      en: 'Guinea-Bissau',
      ar: 'غينيا بيساو',
    },
  },
  {
    label: 'British Indian Ocean Territory',
    dialCode: '+246',
    isoCode: 'IO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/io.svg',
    value: 'British Indian Ocean Territory',
    translationName: {
      en: 'British Indian Ocean Territory',
      ar: 'إقليم المحيط الهندي البريطاني',
    },
  },
  {
    label: 'United States Minor Outlying Islands',
    dialCode: '+246',
    isoCode: 'UMI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/umi.svg',
    value: 'United States Minor Outlying Islands',
    translationName: {
      en: 'United States Minor Outlying Islands',
      ar: 'جزر الولايات المتحدة البعيدة الصغرى',
    },
  },
  {
    label: 'Ascension Island',
    dialCode: '+247',
    isoCode: 'AC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ac.svg',
    value: 'Ascension Island',
    translationName: {
      en: 'Ascension Island',
      ar: 'جزيرة أسنشن',
    },
  },
  {
    label: 'Seychelles',
    dialCode: '+248',
    isoCode: 'SC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sc.svg',
    value: 'Seychelles',
    translationName: {
      en: 'Seychelles',
      ar: 'سيشيل',
    },
  },
  {
    label: 'Rwanda',
    dialCode: '+250',
    isoCode: 'RW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rw.svg',
    value: 'Rwanda',
    translationName: {
      en: 'Rwanda',
      ar: 'رواندا',
    },
  },
  {
    label: 'Ethiopia',
    dialCode: '+251',
    isoCode: 'ET',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/et.svg',
    value: 'Ethiopia',
    translationName: {
      en: 'Ethiopia',
      ar: 'إثيوبيا',
    },
  },
  {
    label: 'Somalia',
    dialCode: '+252',
    isoCode: 'SO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/so.svg',
    value: 'Somalia',
    translationName: {
      en: 'Somalia',
      ar: 'الصومال',
    },
  },
  {
    label: 'Djibouti',
    dialCode: '+253',
    isoCode: 'DJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dj.svg',
    value: 'Djibouti',
    translationName: {
      en: 'Djibouti',
      ar: 'جيبوتي',
    },
  },
  {
    label: 'Kenya',
    dialCode: '+254',
    isoCode: 'KE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ke.svg',
    value: 'Kenya',
    translationName: {
      en: 'Kenya',
      ar: 'كينيا',
    },
  },
  {
    label: 'Tanzania',
    dialCode: '+255',
    isoCode: 'TZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tz.svg',
    value: 'Tanzania, United Republic of Tanzania',
    translationName: {
      en: 'Tanzania',
      ar: 'تنزانيا',
    },
  },
  {
    label: 'Uganda',
    dialCode: '+256',
    isoCode: 'UG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ug.svg',
    value: 'Uganda',
    translationName: {
      en: 'Uganda',
      ar: 'أوغندا',
    },
  },
  {
    label: 'Burundi',
    dialCode: '+257',
    isoCode: 'BI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bi.svg',
    value: 'Burundi',
    translationName: {
      en: 'Burundi',
      ar: 'بوروندي',
    },
  },
  {
    label: 'Mozambique',
    dialCode: '+258',
    isoCode: 'MZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mz.svg',
    value: 'Mozambique',
    translationName: {
      en: 'Mozambique',
      ar: 'موزامبيق',
    },
  },
  {
    label: 'Zambia',
    dialCode: '+260',
    isoCode: 'ZM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zm.svg',
    value: 'Zambia',
    translationName: {
      en: 'Zambia',
      ar: 'زامبيا',
    },
  },
  {
    label: 'Madagascar',
    dialCode: '+261',
    isoCode: 'MG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mg.svg',
    value: 'Madagascar',
    translationName: {
      en: 'Madagascar',
      ar: 'مدغشقر',
    },
  },
  {
    label: 'Mayotte',
    dialCode: '+262',
    isoCode: 'YT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/yt.svg',
    value: 'Mayotte',
    translationName: {
      en: 'Mayotte',
      ar: 'مايوت',
    },
  },
  {
    label: 'Reunion',
    dialCode: '+262',
    isoCode: 'RE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/re.svg',
    value: 'Reunion',
    translationName: {
      en: 'Reunion',
      ar: 'ريونيون',
    },
  },
  {
    label: 'Zimbabwe',
    dialCode: '+263',
    isoCode: 'ZW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/zw.svg',
    value: 'Zimbabwe',
    translationName: {
      en: 'Zimbabwe',
      ar: 'زمبابوي',
    },
  },
  {
    label: 'Namibia',
    dialCode: '+264',
    isoCode: 'NA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/na.svg',
    value: 'Namibia',
    translationName: {
      en: 'Namibia',
      ar: 'ناميبيا',
    },
  },
  {
    label: 'Malawi',
    dialCode: '+265',
    isoCode: 'MW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mw.svg',
    value: 'Malawi',
    translationName: {
      en: 'Malawi',
      ar: 'مالاوي',
    },
  },
  {
    label: 'Lesotho',
    dialCode: '+266',
    isoCode: 'LS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ls.svg',
    value: 'Lesotho',
    translationName: {
      en: 'Lesotho',
      ar: 'ليسوتو',
    },
  },
  {
    label: 'Botswana',
    dialCode: '+267',
    isoCode: 'BW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bw.svg',
    value: 'Botswana',
    translationName: {
      en: 'Botswana',
      ar: 'بوتسوانا',
    },
  },
  {
    label: 'Eswatini',
    dialCode: '+268',
    isoCode: 'SZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sz.svg',
    value: 'Eswatini',
    translationName: {
      en: 'Eswatini',
      ar: 'إيسواتيني',
    },
  },
  {
    label: 'Comoros',
    dialCode: '+269',
    isoCode: 'KM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/km.svg',
    value: 'Comoros',
    translationName: {
      en: 'Comoros',
      ar: 'جزر القمر',
    },
  },
  {
    label: 'South Africa',
    dialCode: '+27',
    isoCode: 'ZA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/za.svg',
    value: 'South Africa',
    translationName: {
      en: 'South Africa',
      ar: 'جنوب أفريقيا',
    },
  },
  {
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    isoCode: 'SH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sh.svg',
    value: 'Saint Helena, Ascension and Tristan Da Cunha',
    translationName: {
      en: 'Saint Helena, Ascension and Tristan Da Cunha',
      ar: 'سانت هيلانة وأسنسيون وتريستان دا كونها',
    },
  },
  {
    label: 'Aruba',
    dialCode: '+297',
    isoCode: 'AW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aw.svg',
    value: 'Aruba',
    translationName: {
      en: 'Aruba',
      ar: 'أروبا',
    },
  },
  {
    label: 'Faroe Islands',
    dialCode: '+298',
    isoCode: 'FO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fo.svg',
    value: 'Faroe Islands',
    translationName: {
      en: 'Faroe Islands',
      ar: 'جزر فارو',
    },
  },
  {
    label: 'Greenland',
    dialCode: '+299',
    isoCode: 'GL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gl.svg',
    value: 'Greenland',
    translationName: {
      en: 'Greenland',
      ar: 'جرينلاند',
    },
  },
  {
    label: 'Greece',
    dialCode: '+30',
    isoCode: 'GR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gr.svg',
    value: 'Greece',
    translationName: {
      en: 'Greece',
      ar: 'اليونان',
    },
  },
  {
    label: 'Kingdom of the Netherlands',
    dialCode: '+31',
    isoCode: 'NL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nl.svg',
    value: 'Netherlands',
    translationName: {
      en: 'Kingdom of the Netherlands',
      ar: 'مملكة هولندا',
    },
  },
  {
    label: 'Belgium',
    dialCode: '+32',
    isoCode: 'BE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/be.svg',
    value: 'Belgium',
    translationName: {
      en: 'Belgium',
      ar: 'بلجيكا',
    },
  },
  {
    label: 'France',
    dialCode: '+33',
    isoCode: 'FR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fr.svg',
    value: 'France',
    translationName: {
      en: 'France',
      ar: 'فرنسا',
    },
  },
  {
    label: 'Spain',
    dialCode: '+34',
    isoCode: 'ES',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/es.svg',
    value: 'Spain',
    translationName: {
      en: 'Spain',
      ar: 'أسبانيا',
    },
  },
  {
    label: 'Gibraltar',
    dialCode: '+350',
    isoCode: 'GI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gi.svg',
    value: 'Gibraltar',
    translationName: {
      en: 'Gibraltar',
      ar: 'جبل طارق',
    },
  },
  {
    label: 'Portugal',
    dialCode: '+351',
    isoCode: 'PT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pt.svg',
    value: 'Portugal',
    translationName: {
      en: 'Portugal',
      ar: 'البرتغال',
    },
  },
  {
    label: 'Luxembourg',
    dialCode: '+352',
    isoCode: 'LU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lu.svg',
    value: 'Luxembourg',
    translationName: {
      en: 'Luxembourg',
      ar: 'لوكسمبورغ',
    },
  },
  {
    label: 'Republic of Ireland',
    dialCode: '+353',
    isoCode: 'IE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ie.svg',
    value: 'Ireland',
    translationName: {
      en: 'Republic of Ireland',
      ar: 'جمهورية أيرلندا',
    },
  },
  {
    label: 'Iceland',
    dialCode: '+354',
    isoCode: 'IS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/is.svg',
    value: 'Iceland',
    translationName: {
      en: 'Iceland',
      ar: 'أيسلندا',
    },
  },
  {
    label: 'Albania',
    dialCode: '+355',
    isoCode: 'AL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/al.svg',
    value: 'Albania',
    translationName: {
      en: 'Albania',
      ar: 'ألبانيا',
    },
  },
  {
    label: 'Malta',
    dialCode: '+356',
    isoCode: 'MT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mt.svg',
    value: 'Malta',
    translationName: {
      en: 'Malta',
      ar: 'مالطا',
    },
  },
  {
    label: 'Cyprus',
    dialCode: '+357',
    isoCode: 'CY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cy.svg',
    value: 'Cyprus',
    translationName: {
      en: 'Cyprus',
      ar: 'قبرص',
    },
  },
  {
    label: 'Åland Islands',
    dialCode: '+358',
    isoCode: 'AX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ax.svg',
    value: 'Åland Islands',
    translationName: {
      en: 'Åland Islands',
      ar: 'جزر آلاند',
    },
  },
  {
    label: 'Finland',
    dialCode: '+358',
    isoCode: 'FI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fi.svg',
    value: 'Finland',
    translationName: {
      en: 'Finland',
      ar: 'فنلندا',
    },
  },
  {
    label: 'Bulgaria',
    dialCode: '+359',
    isoCode: 'BG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bg.svg',
    value: 'Bulgaria',
    translationName: {
      en: 'Bulgaria',
      ar: 'بلغاريا',
    },
  },
  {
    label: 'Hungary',
    dialCode: '+36',
    isoCode: 'HU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hu.svg',
    value: 'Hungary',
    translationName: {
      en: 'Hungary',
      ar: 'المجر',
    },
  },
  {
    label: 'Lithuania',
    dialCode: '+370',
    isoCode: 'LT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lt.svg',
    value: 'Lithuania',
    translationName: {
      en: 'Lithuania',
      ar: 'ليتوانيا',
    },
  },
  {
    label: 'Latvia',
    dialCode: '+371',
    isoCode: 'LV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lv.svg',
    value: 'Latvia',
    translationName: {
      en: 'Latvia',
      ar: 'لاتفيا',
    },
  },
  {
    label: 'Estonia',
    dialCode: '+372',
    isoCode: 'EE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ee.svg',
    value: 'Estonia',
    translationName: {
      en: 'Estonia',
      ar: 'إستونيا',
    },
  },
  {
    label: 'Moldova',
    dialCode: '+373',
    isoCode: 'MD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/md.svg',
    value: 'Moldova (Republic of)',
    translationName: {
      en: 'Moldova',
      ar: 'مولدوفا',
    },
  },
  {
    label: 'Armenia',
    dialCode: '+374',
    isoCode: 'AM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/am.svg',
    value: 'Armenia',
    translationName: {
      en: 'Armenia',
      ar: 'أرمينيا',
    },
  },
  {
    label: 'Belarus',
    dialCode: '+375',
    isoCode: 'BY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/by.svg',
    value: 'Belarus',
    translationName: {
      en: 'Belarus',
      ar: 'بيلاروسيا',
    },
  },
  {
    label: 'Andorra',
    dialCode: '+376',
    isoCode: 'AD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ad.svg',
    value: 'Andorra',
    translationName: {
      en: 'Andorra',
      ar: 'أندورا',
    },
  },
  {
    label: 'Monaco',
    dialCode: '+377',
    isoCode: 'MC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mc.svg',
    value: 'Monaco',
    translationName: {
      en: 'Monaco',
      ar: 'موناكو',
    },
  },
  {
    label: 'San Marino',
    dialCode: '+378',
    isoCode: 'SM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sm.svg',
    value: 'San Marino',
    translationName: {
      en: 'San Marino',
      ar: 'سان مارينو',
    },
  },
  {
    label: 'Vatican City',
    dialCode: '+379',
    isoCode: 'VA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/va.svg',
    value: 'Holy See',
    translationName: {
      en: 'Vatican City',
      ar: 'مدينة الفاتيكان',
    },
  },
  {
    label: 'Ukraine',
    dialCode: '+380',
    isoCode: 'UA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ua.svg',
    value: 'Ukraine',
    translationName: {
      en: 'Ukraine',
      ar: 'أوكرانيا',
    },
  },
  {
    label: 'Serbia',
    dialCode: '+381',
    isoCode: 'RS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/rs.svg',
    value: 'Serbia',
    translationName: {
      en: 'Serbia',
      ar: 'صربيا',
    },
  },
  {
    label: 'Montenegro',
    dialCode: '+382',
    isoCode: 'ME',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/me.svg',
    value: 'Montenegro',
    translationName: {
      en: 'Montenegro',
      ar: 'الجبل الأسود',
    },
  },
  {
    label: 'Kesovo',
    dialCode: '+383',
    isoCode: 'XK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/xk.svg',
    value: 'Kesovo',
    translationName: {
      en: 'Kesovo',
      ar: 'كوسوفو',
    },
  },
  {
    label: 'Croatia',
    dialCode: '+385',
    isoCode: 'HR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hr.svg',
    value: 'Croatia',
    translationName: {
      en: 'Croatia',
      ar: 'كرواتيا',
    },
  },
  {
    label: 'Slovenia',
    dialCode: '+386',
    isoCode: 'SI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/si.svg',
    value: 'Slovenia',
    translationName: {
      en: 'Slovenia',
      ar: 'سلوفينيا',
    },
  },
  {
    label: 'Bosnia and Herzegovina',
    dialCode: '+387',
    isoCode: 'BA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ba.svg',
    value: 'Bosnia and Herzegovina',
    translationName: {
      en: 'Bosnia and Herzegovina',
      ar: 'البوسنة والهرسك',
    },
  },
  {
    label: 'North Macedonia',
    dialCode: '+389',
    isoCode: 'MK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mk.svg',
    value: 'Macedonia (the former Yugoslav Republic of)',
    translationName: {
      en: 'North Macedonia',
      ar: 'مقدونيا الشمالية',
    },
  },
  {
    label: 'Italy',
    dialCode: '+39',
    isoCode: 'IT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/it.svg',
    value: 'Italy',
    translationName: {
      en: 'Italy',
      ar: 'إيطاليا',
    },
  },
  {
    label: 'Romania',
    dialCode: '+40',
    isoCode: 'RO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ro.svg',
    value: 'Romania',
    translationName: {
      en: 'Romania',
      ar: 'رومانيا',
    },
  },
  {
    label: 'Switzerland',
    dialCode: '+41',
    isoCode: 'CH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ch.svg',
    value: 'Switzerland',
    translationName: {
      en: 'Switzerland',
      ar: 'سويسرا',
    },
  },
  {
    label: 'Czech Republic',
    dialCode: '+420',
    isoCode: 'CZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cz.svg',
    value: 'Czech Republic',
    translationName: {
      en: 'Czech Republic',
      ar: 'الجمهورية التشيكية',
    },
  },
  {
    label: 'Slovakia',
    dialCode: '+421',
    isoCode: 'SK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sk.svg',
    value: 'Slovakia',
    translationName: {
      en: 'Slovakia',
      ar: 'سلوفاكيا',
    },
  },
  {
    label: 'Liechtenstein',
    dialCode: '+423',
    isoCode: 'LI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/li.svg',
    value: 'Liechtenstein',
    translationName: {
      en: 'Liechtenstein',
      ar: 'ليختنشتاين',
    },
  },
  {
    label: 'Austria',
    dialCode: '+43',
    isoCode: 'AT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/at.svg',
    value: 'Austria',
    translationName: {
      en: 'Austria',
      ar: 'النمسا',
    },
  },
  {
    label: 'United Kingdom',
    dialCode: '+44',
    isoCode: 'GB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gb.svg',
    value: 'United Kingdom of Great Britain and Northern Ireland',
    translationName: {
      en: 'United Kingdom',
      ar: 'المملكة المتحدة',
    },
  },
  {
    label: 'Bailiwick of Guernsey',
    dialCode: '+44-1481',
    isoCode: 'GG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gg.svg',
    value: 'Guernsey',
    translationName: {
      en: 'Bailiwick of Guernsey',
      ar: 'بيليويك غيرنسي',
    },
  },
  {
    label: 'Jersey',
    dialCode: '+44-1534',
    isoCode: 'JE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/je.svg',
    value: 'Jersey',
    translationName: {
      en: 'Jersey',
      ar: 'جيرسي',
    },
  },
  {
    label: 'Isle of Man',
    dialCode: '+44-1624',
    isoCode: 'IM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/im.svg',
    value: 'Isle of Man',
    translationName: {
      en: 'Isle of Man',
      ar: 'جزيرة مان',
    },
  },
  {
    label: 'Denmark',
    dialCode: '+45',
    isoCode: 'DK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/dk.svg',
    value: 'Denmark',
    translationName: {
      en: 'Denmark',
      ar: 'الدنمارك ',
    },
  },
  {
    label: 'Sweden',
    dialCode: '+46',
    isoCode: 'SE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/se.svg',
    value: 'Sweden',
    translationName: {
      en: 'Sweden',
      ar: 'السويد',
    },
  },
  {
    label: 'Norway',
    dialCode: '+47',
    isoCode: 'NO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/no.svg',
    value: 'Norway',
    translationName: {
      en: 'Norway',
      ar: 'النرويج',
    },
  },
  {
    label: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    isoCode: 'SJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sj.svg',
    value: 'Svalbard and Jan Mayen',
    translationName: {
      en: 'Svalbard and Jan Mayen',
      ar: 'سفالبارد وجان ماين',
    },
  },
  {
    label: 'Poland',
    dialCode: '+48',
    isoCode: 'PL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pl.svg',
    value: 'Poland',
    translationName: {
      en: 'Poland',
      ar: 'بولندا',
    },
  },
  {
    label: 'Germany',
    dialCode: '+49',
    isoCode: 'DE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/de.svg',
    value: 'Germany',
    translationName: {
      en: 'Germany',
      ar: 'ألمانيا',
    },
  },
  {
    label: 'Falkland Islands',
    dialCode: '+500',
    isoCode: 'FK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fk.svg',
    value: 'Falkland Islands (Malvinas)',
    translationName: {
      en: 'Falkland Islands',
      ar: 'جزر فوكلاند',
    },
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    isoCode: 'GS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gs.svg',
    value: 'South Georgia and the South Sandwich Islands',
    translationName: {
      en: 'South Georgia and the South Sandwich Islands',
      ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    },
  },
  {
    label: 'Belize',
    dialCode: '+501',
    isoCode: 'BZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bz.svg',
    value: 'Belize',
    translationName: {
      en: 'Belize',
      ar: 'بليز',
    },
  },
  {
    label: 'Guatemala',
    dialCode: '+502',
    isoCode: 'GT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gt.svg',
    value: 'Guatemala',
    translationName: {
      en: 'Guatemala',
      ar: 'غواتيمالا',
    },
  },
  {
    label: 'El Salvador',
    dialCode: '+503',
    isoCode: 'SV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sv.svg',
    value: 'El Salvador',
    translationName: {
      en: 'El Salvador',
      ar: 'السلفادور',
    },
  },
  {
    label: 'Honduras',
    dialCode: '+504',
    isoCode: 'HN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hn.svg',
    value: 'Honduras',
    translationName: {
      en: 'Honduras',
      ar: 'هندوراس',
    },
  },
  {
    label: 'Nicaragua',
    dialCode: '+505',
    isoCode: 'NI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ni.svg',
    value: 'Nicaragua',
    translationName: {
      en: 'Nicaragua',
      ar: 'نيكاراغوا',
    },
  },
  {
    label: 'Costa Rica',
    dialCode: '+506',
    isoCode: 'CR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cr.svg',
    value: 'Costa Rica',
    translationName: {
      en: 'Costa Rica',
      ar: 'كوستاريكا',
    },
  },
  {
    label: 'Panama',
    dialCode: '+507',
    isoCode: 'PA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pa.svg',
    value: 'Panama',
    translationName: {
      en: 'Panama',
      ar: 'بنما',
    },
  },
  {
    label: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    isoCode: 'PM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pm.svg',
    value: 'Saint Pierre and Miquelon',
    translationName: {
      en: 'Saint Pierre and Miquelon',
      ar: 'سانت بيير وميكلون',
    },
  },
  {
    label: 'Haiti',
    dialCode: '+509',
    isoCode: 'HT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ht.svg',
    value: 'Haiti',
    translationName: {
      en: 'Haiti',
      ar: 'هايتي',
    },
  },
  {
    label: 'Peru',
    dialCode: '+51',
    isoCode: 'PE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pe.svg',
    value: 'Peru',
    translationName: {
      en: 'Peru',
      ar: 'بيرو',
    },
  },
  {
    label: 'Mexico',
    dialCode: '+52',
    isoCode: 'MX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mx.svg',
    value: 'Mexico',
    translationName: {
      en: 'Mexico',
      ar: 'المكسيك',
    },
  },
  {
    label: 'Cuba',
    dialCode: '+53',
    isoCode: 'CU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cu.svg',
    value: 'Cuba',
    translationName: {
      en: 'Cuba',
      ar: 'كوبا',
    },
  },
  {
    label: 'Argentina',
    dialCode: '+54',
    isoCode: 'AR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ar.svg',
    value: 'Argentina',
    translationName: {
      en: 'Argentina',
      ar: 'الأرجنتين',
    },
  },
  {
    label: 'Brazil',
    dialCode: '+55',
    isoCode: 'BR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/br.svg',
    value: 'Brazil',
    translationName: {
      en: 'Brazil',
      ar: 'البرازيل',
    },
  },
  {
    label: 'Chile',
    dialCode: '+56',
    isoCode: 'CL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cl.svg',
    value: 'Chile',
    translationName: {
      en: 'Chile',
      ar: 'شيلي',
    },
  },
  {
    label: 'Colombia',
    dialCode: '+57',
    isoCode: 'CO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/co.svg',
    value: 'Colombia',
    translationName: {
      en: 'Colombia',
      ar: 'كولومبيا',
    },
  },
  {
    label: 'Venezuela',
    dialCode: '+58',
    isoCode: 'VE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ve.svg',
    value: 'Venezuela (Bolivarian Republic of)',
    translationName: {
      en: 'Venezuela',
      ar: 'فنزويلا',
    },
  },
  {
    label: 'Guadeloupe',
    dialCode: '+590',
    isoCode: 'GP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gp.svg',
    value: 'Guadeloupe',
    translationName: {
      en: 'Guadeloupe',
      ar: 'جوادلوب',
    },
  },
  {
    label: 'Saint Barthelemy',
    dialCode: '+590',
    isoCode: 'BL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bl.svg',
    value: 'Saint Barthelemy',
    translationName: {
      en: 'Saint Barthelemy',
      ar: 'سانت بارتيليمي',
    },
  },
  {
    label: 'Collectivity of Saint Martin',
    dialCode: '+590',
    isoCode: 'MF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mf.svg',
    value: 'Saint Martin (French part)',
    translationName: {
      en: 'Collectivity of Saint Martin',
      ar: 'جماعة سانت مارتن',
    },
  },
  {
    label: 'Bolivia',
    dialCode: '+591',
    isoCode: 'BO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bo.svg',
    value: 'Bolivia (Plurinational State of)',
    translationName: {
      en: 'Bolivia',
      ar: 'بوليفيا',
    },
  },
  {
    label: 'Guyana',
    dialCode: '+592',
    isoCode: 'GY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gy.svg',
    value: 'Guyana',
    translationName: {
      en: 'Guyana',
      ar: 'غيانا',
    },
  },
  {
    label: 'Ecuador',
    dialCode: '+593',
    isoCode: 'EC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ec.svg',
    value: 'Ecuador',
    translationName: {
      en: 'Ecuador',
      ar: 'إكوادور',
    },
  },
  {
    label: 'French Guiana',
    dialCode: '+594',
    isoCode: 'GF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/gf.svg',
    value: 'French Guiana',
    translationName: {
      en: 'French Guiana',
      ar: 'غيانا الفرنسية',
    },
  },
  {
    label: 'Paraguay',
    dialCode: '+595',
    isoCode: 'PY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/py.svg',
    value: 'Paraguay',
    translationName: {
      en: 'Paraguay',
      ar: 'باراغواي',
    },
  },
  {
    label: 'Martinique',
    dialCode: '+596',
    isoCode: 'MQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mq.svg',
    value: 'Martinique',
    translationName: {
      en: 'Martinique',
      ar: 'مارتينيك',
    },
  },
  {
    label: 'Suriname',
    dialCode: '+597',
    isoCode: 'SR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sr.svg',
    value: 'Suriname',
    translationName: {
      en: 'Surivalue',
      ar: 'Surivalue',
    },
  },
  {
    label: 'Uruguay',
    dialCode: '+598',
    isoCode: 'UY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uy.svg',
    value: 'Uruguay',
    translationName: {
      en: 'Uruguay',
      ar: 'أوروغواي',
    },
  },
  {
    label: 'Netherlands Antilles',
    dialCode: '+599',
    isoCode: 'AN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/an.svg',
    value: 'Netherlands Antilles',
    translationName: {
      en: 'Netherlands Antilles',
      ar: 'نيذرلاند أنتيلس',
    },
  },
  {
    label: 'Malaysia',
    dialCode: '+60',
    isoCode: 'MY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/my.svg',
    value: 'Malaysia',
    translationName: {
      en: 'Malaysia',
      ar: 'ماليزيا',
    },
  },
  {
    label: 'Australia',
    dialCode: '+61',
    isoCode: 'AU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/au.svg',
    value: 'Australia',
    translationName: {
      en: 'Australia',
      ar: 'أستراليا',
    },
  },
  {
    label: 'Christmas Island',
    dialCode: '+61',
    isoCode: 'CX',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cx.svg',
    value: 'Christmas Island',
    translationName: {
      en: 'Christmas Island',
      ar: 'جزيرة كريسماس',
    },
  },
  {
    label: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    isoCode: 'CC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cc.svg',
    value: 'Cocos (Keeling) Islands',
    translationName: {
      en: 'Cocos (Keeling) Islands',
      ar: 'جزر كوكوس',
    },
  },
  {
    label: 'Indonesia',
    dialCode: '+62',
    isoCode: 'ID',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/id.svg',
    value: 'Indonesia',
    translationName: {
      en: 'Indonesia',
      ar: 'اندونيسيا',
    },
  },
  {
    label: 'Philippines',
    dialCode: '+63',
    isoCode: 'PH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ph.svg',
    value: 'Philippines',
    translationName: {
      en: 'Philippines',
      ar: 'الفلبين',
    },
  },
  {
    label: 'New Zealand',
    dialCode: '+64',
    isoCode: 'NZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nz.svg',
    value: 'New Zealand',
    translationName: {
      en: 'New Zealand',
      ar: 'نيوزلندا',
    },
  },
  {
    label: 'Singapore',
    dialCode: '+65',
    isoCode: 'SG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sg.svg',
    value: 'Singapore',
    translationName: {
      en: 'Singapore',
      ar: 'سنغافورة',
    },
  },
  {
    label: 'Thailand',
    dialCode: '+66',
    isoCode: 'TH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/th.svg',
    value: 'Thailand',
    translationName: {
      en: 'Thailand',
      ar: 'تايلاند',
    },
  },
  {
    label: 'East Timor',
    dialCode: '+670',
    isoCode: 'TL',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tl.svg',
    value: 'Timor-Leste',
    translationName: {
      en: 'East Timor',
      ar: 'تيمور الشرقية',
    },
  },
  {
    label: 'Antarctica',
    dialCode: '+672',
    isoCode: 'AQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/aq.svg',
    value: 'Antarctica',
    translationName: {
      en: 'Antarctica',
      ar: 'أنتاركتيكا',
    },
  },
  {
    label: 'Norfolk Island',
    dialCode: '+672',
    isoCode: 'NF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nf.svg',
    value: 'Norfolk Island',
    translationName: {
      en: 'Norfolk Island',
      ar: ',جزيرة نورفولك',
    },
  },
  {
    label: 'Brunei Darussalam',
    dialCode: '+673',
    isoCode: 'BN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bn.svg',
    value: 'Brunei Darussalam',
    translationName: {
      en: 'Brunei Darussalam',
      ar: 'بروناي دار السلام',
    },
  },
  {
    label: 'Nauru',
    dialCode: '+674',
    isoCode: 'NR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nr.svg',
    value: 'Nauru',
    translationName: {
      en: 'Nauru',
      ar: 'ناورو',
    },
  },
  {
    label: 'Papua New Guinea',
    dialCode: '+675',
    isoCode: 'PG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pg.svg',
    value: 'Papua New Guinea',
    translationName: {
      en: 'Papua New Guinea',
      ar: 'بابوا غينيا الجديدة',
    },
  },
  {
    label: 'Tonga',
    dialCode: '+676',
    isoCode: 'TO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/to.svg',
    value: 'Tonga',
    translationName: {
      en: 'Tonga',
      ar: 'تونغا',
    },
  },
  {
    label: 'Solomon Islands',
    dialCode: '+677',
    isoCode: 'SB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sb.svg',
    value: 'Solomon Islands',
    translationName: {
      en: 'Solomon Islands',
      ar: 'جزر سليمان',
    },
  },
  {
    label: 'Vanuatu',
    dialCode: '+678',
    isoCode: 'VU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/vu.svg',
    value: 'Vanuatu',
    translationName: {
      en: 'Vanuatu',
      ar: 'فانواتو',
    },
  },
  {
    label: 'Fiji',
    dialCode: '+679',
    isoCode: 'FJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fj.svg',
    value: 'Fiji',
    translationName: {
      en: 'Fiji',
      ar: 'فيجي',
    },
  },
  {
    label: 'Palau',
    dialCode: '+680',
    isoCode: 'PW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pw.svg',
    value: 'Palau',
    translationName: {
      en: 'Palau',
      ar: 'بالاو',
    },
  },
  {
    label: 'Wallis and Futuna',
    dialCode: '+681',
    isoCode: 'WF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/wf.svg',
    value: 'Wallis and Futuna',
    translationName: {
      en: 'Wallis and Futuna',
      ar: 'واليس وفوتونا',
    },
  },
  {
    label: 'Cook Islands',
    dialCode: '+682',
    isoCode: 'CK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ck.svg',
    value: 'Cook Islands',
    translationName: {
      en: 'Cook Islands',
      ar: 'جزر كوك',
    },
  },
  {
    label: 'Niue',
    dialCode: '+683',
    isoCode: 'NU',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nu.svg',
    value: 'Niue',
    translationName: {
      en: 'Niue',
      ar: 'نيوي',
    },
  },
  {
    label: 'Samoa',
    dialCode: '+685',
    isoCode: 'WS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ws.svg',
    value: 'Samoa',
    translationName: {
      en: 'Samoa',
      ar: 'ساموا',
    },
  },
  {
    label: 'Kiribati',
    dialCode: '+686',
    isoCode: 'KI',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ki.svg',
    value: 'Kiribati',
    translationName: {
      en: 'Kiribati',
      ar: 'كيريباتي',
    },
  },
  {
    label: 'New Caledonia',
    dialCode: '+687',
    isoCode: 'NC',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/nc.svg',
    value: 'New Caledonia',
    translationName: {
      en: 'New Caledonia',
      ar: 'كاليدونيا الجديدة',
    },
  },
  {
    label: 'Tuvalu',
    dialCode: '+688',
    isoCode: 'TV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tv.svg',
    value: 'Tuvalu',
    translationName: {
      en: 'Tuvalu',
      ar: 'توفالو',
    },
  },
  {
    label: 'French Polynesia',
    dialCode: '+689',
    isoCode: 'PF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pf.svg',
    value: 'French Polynesia',
    translationName: {
      en: 'French Polynesia',
      ar: 'بولينريا الفرنسية',
    },
  },
  {
    label: 'Tokelau',
    dialCode: '+690',
    isoCode: 'TK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tk.svg',
    value: 'Tokelau',
    translationName: {
      en: 'Tokelau',
      ar: 'توكيلاو',
    },
  },
  {
    label: 'Federated States of Micronesia',
    dialCode: '+691',
    isoCode: 'FM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/fm.svg',
    value: 'Micronesia (Federated States of)',
    translationName: {
      en: 'Federated States of Micronesia',
      ar: 'ولايات ميكرونيزيا الموحدة ',
    },
  },
  {
    label: 'Marshall Islands',
    dialCode: '+692',
    isoCode: 'MH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mh.svg',
    value: 'Marshall Islands',
    translationName: {
      en: 'Marshall Islands',
      ar: 'جزر مارشال',
    },
  },
  {
    label: 'Kazakhstan',
    dialCode: '+77',
    isoCode: 'KZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kz.svg',
    value: 'Kazakhstan',
    translationName: {
      en: 'Kazakhstan',
      ar: 'كازاخستان',
    },
  },
  {
    label: 'Japan',
    dialCode: '+81',
    isoCode: 'JP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jp.svg',
    value: 'Japan',
    translationName: {
      en: 'Japan',
      ar: 'اليابان',
    },
  },
  {
    label: 'South Korea',
    dialCode: '+82',
    isoCode: 'KR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kr.svg',
    value: 'Korea, Republic of South Korea',
    translationName: {
      en: 'South Korea',
      ar: 'كوريا الجنوبية',
    },
  },
  {
    label: 'Hong Kong',
    dialCode: '+852',
    isoCode: 'HK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/hk.svg',
    value: 'Hong Kong',
    translationName: {
      en: 'Hong Kong',
      ar: 'هونغ كونغ',
    },
  },
  {
    label: 'Macau',
    dialCode: '+853',
    isoCode: 'MO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mo.svg',
    value: 'Macau',
    translationName: {
      en: 'Macau',
      ar: 'ماكاو',
    },
  },
  {
    label: 'Cambodia',
    dialCode: '+855',
    isoCode: 'KH',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kh.svg',
    value: 'Cambodia',
    translationName: {
      en: 'Cambodia',
      ar: 'كمبوديا',
    },
  },
  {
    label: 'Laos',
    dialCode: '+856',
    isoCode: 'LA',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/la.svg',
    value: 'Laos',
    translationName: {
      en: 'Laos',
      ar: 'لاوس',
    },
  },
  {
    label: 'China',
    dialCode: '+86',
    isoCode: 'CN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/cn.svg',
    value: 'China',
    translationName: {
      en: 'China',
      ar: 'الصين',
    },
  },
  {
    label: 'Pitcairn Islands',
    dialCode: '+872',
    isoCode: 'PN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pn.svg',
    value: 'Pitcairn',
    translationName: {
      en: 'Pitcairn Islands',
      ar: 'جزر بيتكيرن',
    },
  },
  {
    label: 'Bangladesh',
    dialCode: '+880',
    isoCode: 'BD',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bd.svg',
    value: 'Bangladesh',
    translationName: {
      en: 'Bangladesh',
      ar: 'بنغلاديش',
    },
  },
  {
    label: 'Taiwan, China',
    dialCode: '+886',
    isoCode: 'TW',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tw.svg',
    value: 'Taiwan',
    translationName: {
      en: 'Taiwan, China',
      ar: 'الصين تايوان',
    },
  },
  {
    label: 'Turkey',
    dialCode: '+90',
    isoCode: 'TR',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tr.svg',
    value: 'Turkey',
    translationName: {
      en: 'Turkey',
      ar: 'تركيا',
    },
  },
  {
    label: 'India',
    dialCode: '+91',
    isoCode: 'IN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/in.svg',
    value: 'India',
    translationName: {
      en: 'India',
      ar: 'الهند',
    },
  },
  {
    label: 'Pakistan',
    dialCode: '+92',
    isoCode: 'PK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/pk.svg',
    value: 'Pakistan',
    translationName: {
      en: 'Pakistan',
      ar: 'باكستان',
    },
  },
  {
    label: 'Afghanistan',
    dialCode: '+93',
    isoCode: 'AF',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/af.svg',
    value: 'Afghanistan',
    translationName: {
      en: 'Afghanistan',
      ar: 'افغانستان',
    },
  },
  {
    label: 'Sri Lanka',
    dialCode: '+94',
    isoCode: 'LK',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lk.svg',
    value: 'Sri Lanka',
    translationName: {
      en: 'Sri Lanka',
      ar: 'سيريلانكا',
    },
  },
  {
    label: 'Myanmar',
    dialCode: '+95',
    isoCode: 'MM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mm.svg',
    value: 'Myanmar',
    translationName: {
      en: 'Myanmar',
      ar: 'ميانمار',
    },
  },
  {
    label: 'Maldives',
    dialCode: '+960',
    isoCode: 'MV',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mv.svg',
    value: 'Maldives',
    translationName: {
      en: 'Maldives',
      ar: 'المالديف',
    },
  },
  {
    label: 'Lebanon',
    dialCode: '+961',
    isoCode: 'LB',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/lb.svg',
    value: 'Lebanon',
    translationName: {
      en: 'Lebanon',
      ar: 'لبنان',
    },
  },
  {
    label: 'Jordan',
    dialCode: '+962',
    isoCode: 'JO',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/jo.svg',
    value: 'Jordan',
    translationName: {
      en: 'Jordan',
      ar: 'الأردن',
    },
  },
  {
    label: 'Syria',
    dialCode: '+963',
    isoCode: 'SY',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/sy.svg',
    value: 'Syrian Arab Republic',
    translationName: {
      en: 'Syria',
      ar: 'سوريا',
    },
  },
  {
    label: 'Iraq',
    dialCode: '+964',
    isoCode: 'IQ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/iq.svg',
    value: 'Iraq',
    translationName: {
      en: 'Iraq',
      ar: 'العراق',
    },
  },
  {
    label: 'Yemen',
    dialCode: '+967',
    isoCode: 'YE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ye.svg',
    value: 'Yemen',
    translationName: {
      en: 'Yemen',
      ar: 'اليمن',
    },
  },
  {
    label: 'Palestine',
    dialCode: '+970',
    isoCode: 'PS',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ps.svg',
    value: 'Palestine',
    translationName: {
      en: 'Palestine',
      ar: 'فلسطين',
    },
  },
  {
    label: 'Bhutan',
    dialCode: '+975',
    isoCode: 'BT',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/bt.svg',
    value: 'Bhutan',
    translationName: {
      en: 'Bhutan',
      ar: 'بوتان',
    },
  },
  {
    label: 'Mongolia',
    dialCode: '+976',
    isoCode: 'MN',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/mn.svg',
    value: 'Mongolia',
    translationName: {
      en: 'Mongolia',
      ar: 'منغوليا',
    },
  },
  {
    label: 'Nepal',
    dialCode: '+977',
    isoCode: 'NP',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/np.svg',
    value: 'Nepal',
    translationName: {
      en: 'Nepal',
      ar: 'النيبال',
    },
  },
  {
    label: 'Tajikistan',
    dialCode: '+992',
    isoCode: 'TJ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tj.svg',
    value: 'Tajikistan',
    translationName: {
      en: 'Tajikistan',
      ar: 'طاجيكستان',
    },
  },
  {
    label: 'Turkmenistan',
    dialCode: '+993',
    isoCode: 'TM',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/tm.svg',
    value: 'Turkmenistan',
    translationName: {
      en: 'Turkmenistan',
      ar: 'تركمانستان',
    },
  },
  {
    label: 'Azerbaijan',
    dialCode: '+994',
    isoCode: 'AZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/az.svg',
    value: 'Azerbaijan',
    translationName: {
      en: 'Azerbaijan',
      ar: 'أذربيجان',
    },
  },
  {
    label: 'Georgia (country)',
    dialCode: '+995',
    isoCode: 'GE',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/ge.svg',
    value: 'Georgia',
    translationName: {
      en: 'Georgia (country)',
      ar: 'جورجيا',
    },
  },
  {
    label: 'Kyrgyzstan',
    dialCode: '+996',
    isoCode: 'KG',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/kg.svg',
    value: 'Kyrgyzstan',
    translationName: {
      en: 'Kyrgyzstan',
      ar: 'قيرغيزستان',
    },
  },
  {
    label: 'Uzbekistan',
    dialCode: '+998',
    isoCode: 'UZ',
    flag: 'https://cdn.kcak11.com/CountryFlags/countries/uz.svg',
    value: 'Uzbekistan',
    translationName: {
      en: 'Uzbekistan',
      ar: 'أوزباكستان',
    },
  },
];
