import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useParams, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

import { MainLayout } from '../../components/Layout/MainLayout';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { NotFoundPage } from '../404NotFound/NotFoundPage';
import { useGetSingleProductQuery } from '../../services/products';
import { SingleProduct } from './SingleProduct';

import './SingleProduct.scss';
import { TemporaryUnavailablePage } from '../TemporarilyUnavailable';

export const ProductPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const {
    data, error, isFetching, refetch,
  } = useGetSingleProductQuery(slug);

  useEffect(() => {
    i18next.on('languageChanged', refetch);
    return () => {
      i18next.off('languageChanged', refetch);
    };
  }, []);

  useEffect(() => {
    if (error?.status === 301) {
      navigate(`/products/${error.data.slug}`, { replace: true });
    }
  }, [error, navigate, slug]);

  if (isFetching) {
    return (
      <MainLayout>
        <Container className="single-product-container">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (error && error.status === 301) {
    return (
      <MainLayout>
        <Container className="single-product-container">
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (error && error.status === 403) {
    return (
      <TemporaryUnavailablePage />
    );
  }

  if (error) {
    return <NotFoundPage />;
  }

  return <SingleProduct product={data} />;
};
