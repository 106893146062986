import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MainLayout } from '../../components/Layout/MainLayout';
import { Text } from '../../components/Text/Text';

import './staticPage.scss';
import { RiyalSymbol } from '../../components/RiyalSymbol/RiyalSymbol';

export const Privacy = () => {
  const { t, i18n } = useTranslation('application');

  const content_en = (
    <>
      <Helmet>
        <title>{t('seo.privacyPolicy.title')}</title>
        <meta name="description" content={t('seo.privacyPolicy.description')} />
      </Helmet>
      <Text type="headline2">Our Policies</Text>
      <Text type="headline3">Quality Policy:</Text>
      <Text type="body1">Camel Step Co. recognizes the value of Progresive Improvement revolving around its business environment and culture. Our employees are our first priority providing them a state of the art Work Environment to build a productive and healthy culture. We strive to consistently meet and even exceed our Customer Satisfaction and Expectations throughout the implementation of corporate principles and initiatives that comply with local and international Regulations and Regulators</Text>
      <Text type="headline3">Privacy Policy:</Text>
      <Text type="body1">Users can visit our site anonymously. However, we implemented serval security measures when a user places an order, submits, or accesses their information through a secure gateway provider to protect their data. In addition, all credit cards information is encrypted and we never save them at our data base or servers.</Text>
      <Text type="headline3">Order Policy:</Text>
      <Text type="body1">Orders that are not paid within three days will be canceled The customer can cancel the order and claim back the purchase amount only before shipping the order. The amount will be refunded maximum by 20 working days.</Text>
      <Text type="headline3">Pick-up Policy:</Text>
      <ol>
        <li><Text type="body1">The order is canceled when it passes 5 days without picking it up from receiving notification.</Text></li>
        <li><Text type="body1">During emergency conditions, Camelstep can suspend the pick-up service or deliver the shipment through a third partner.</Text></li>
        <li><Text type="body1">In the event that the customer is unable to receive the shipment, the order is considered canceled and the amount due is fully refunded as a balance in the customer’s account on the website. Hence, if the customer wants to refund the amount to the credit card used in the purchase, he must contact us to do so via WhatsApp: 966920000689</Text></li>
        <li><Text type="body1">Pick-up service is available during business days from Sunday to Thursday.</Text></li>
      </ol>
      <Text type="headline3">Delivery policy:</Text>
      <ol>
        <li>
          <Text type="body1">Delivery inside Saudi Arabia takes 3-5 working days.</Text>
        </li>
        <li>
          <Text type="body1">Delivery to Gulf countries wihtin 5-10 working days.</Text>
        </li>
        <li>
          <Text type="body1">Camel Step Co is not liable for the damage caused by the carrier.</Text>
        </li>
        <li>
          <Text type="body1">Camel Step Co is not responsible of any delivery delays of the shipment that are caused by the carrier.</Text>
        </li>
        <li>
          <Text type="body1">Returned order due to the lack of response to the carrier, reschedules or mistakes at the address entered, the customer shall bear the shipping value.</Text>
        </li>
        <li>
          <Text type="body1">Customer Obligation: When placing an order for shipping outside the Kingdom of Saudi Arabia, the customer agrees to assume full responsibility for any customs duties, taxes, or fees imposed by the customs authorities of their country. These charges are not included in the product price or shipping fees displayed at checkout.</Text>
        </li>
        <li>
          <Text type="body1">Payment of Customs Duties: It is the sole responsibility of the customer to ensure the timely payment of any customs duties required for the importation of the ordered products. Failure to pay these duties may result in delays, additional charges, or the return of the shipment to the camel step company. If a shipment is returned due to customer negligence in paying the required duty or shipping charges at the destination, Camel Step reserves the right to charge the customer for shipping costs and expenses related to the shipment return.</Text>
        </li>
      </ol>
      <Text type="headline3">Training Programs policy:</Text>
      <Text type="body1">The trainee can cancel and full refund their trianing program 3 working days before the start of their training program with a valid purchase proof.</Text>
      <Text type="body1">The trainee may rescheduale their training program date with the avalible trainers training scheduale 3 working before the start of their training program with a valid purchase proof, to do so kindly reach us via WhatsApp: 966920000689.</Text>
      <Text type="body1">The trainee may recive a reschedualing notification from us regarding their training program date & time 3 working days before the start of their training program in case of insufficent number of registrations.</Text>
      <Text type="headline3">Warranty policy:</Text>
      <Text type="body1">The policy includes the terms of the warranty for electrical products and tools purchased from Camel Step for two years from the purchase date, for which the Camel Step is a distributor of the products, defining the policy limits and the basic provisions for warranty, return and replacement terms.</Text>
      <Text type="headline3">Terms and Conditions for Warranty:</Text>
      <ol>
        <li><Text type="body1">The original invoice is required to verify and validate the warranty period in order to benefit from warranty services. And the unauthorized repair attempts are excluded from warranty service.</Text></li>
        <li><Text type="body1">The warranty covers free repair or replacement service in case of manufacturing defect.</Text></li>
        <li>
          <Text type="body1">Warranty shall be excluded from faults caused by:</Text>
          <ul>
            <li><Text type="body1">Misuse of the product other than what it was manufactured for and its intended purpose, such as: fractures or strokes, or exposure of the device to high humidity or high temperature; operation of water heaters without filling them with water.</Text></li>
            <li><Text type="body1">tampering and trying to repair outside the authorized service center such as: (Trying to use Unauthorized parts, tampering with internal components).</Text></li>
            <li><Text type="body1">Not following the instructions of use in the product manual or the instructions referred to in the e-pages when operating the device.</Text></li>
          </ul>
        </li>
        <li>
          <Text type="body1">The warranty does not cover any of the following:</Text>
          <ul>
            <li><Text type="body1">Hand tools such as grinders and dripping tools.</Text></li>
            <li><Text type="body1">Consumed items such as gears, filters, rubbers, etc.</Text></li>
            <li><Text type="body1">Accessories or external parts such as the external structure, handles, or so on and any parts that the customer has added.</Text></li>
          </ul>
        </li>
        <li><Text type="body1">The repairing duration is(15) days maximum for the defects covered by the guarantee starting from the date of issuance of the repair order from the service center of Camel step Company (not including the time required to order the unavailable parts, and the time of shipping the device to and from the customer).</Text></li>
        <li><Text type="body1">If spare parts are not available to complete the maintenance, the parts may be ordered from the factory up to a maximum of (14) days from the date of ordering and approval of the parts. Therefore, the agreed repair time will change.</Text></li>
        <li><Text type="body1">Extend the product warranty period equivalent to the delay in the supply of spare parts or starting Maintenance work or completion.</Text></li>
        <li><Text type="body1">If there is a factory defect that has been repaired twice and the problem is not solved, the product is replaced with Same product.</Text></li>
        <li><Text type="body1">The customer must ensure the safety of the device before receiving it from the service center and is entitled to check before receiving.</Text></li>
        <li><Text type="body1">Customer is entitled to one month warranty period on serviced parts only In case the warranty expires.</Text></li>
        <li><Text type="body1">Customer is entitled to a temporary device of the same category or equivalent( purchase price of the device÷ 400) for each day of delay.</Text></li>
        <li><Text type="body1">A customer who is outside the city where the service center is located is entitled to apply for a shipping policy from his place of residence to the service center, provided that the warranty is valid and the warranty includes the malfunction of the machine.</Text></li>
        <li><Text type="body1">The service center is committed to provide maintenance service for all products of that was purchased by Camel Step even after the warranty expires.</Text></li>
        <li>
          <Text type="body1">
            A customer who owns a device not covered by the warranty service can request to open a maintenance ticket to check the device at the service center for
            {' '}
            <RiyalSymbol size={14} />
            {' '}
            150 and then approve the maintenance and spare parts costs.
          </Text>
        </li>
        <li><Text type="body1">The customer must receive his device on the date specified in the repair ticket, the message sent via e-mail or text messages so that the customer is notified. Within a month If the customer does not receive the device, the service center is not responsible for the device and can act fully with it.</Text></li>
        <li><Text type="body1">Products provided by Camel Step from another local distributor are maintained by the product distributor.</Text></li>
        <li><Text type="body1">Customer can contact us either to open or submit a maintenance request or repair an emergency via WhatsApp: 966920000689</Text></li>
      </ol>
      <Text type="headline3">Terms and Conditions of Replacement and Return Policy:</Text>
      <ol>
        <li><Text type="body1">Replacement and return includes all items except: Filters, roasted coffee bags and drinks.</Text></li>
        <li><Text type="body1">To take advantage of the exchange and return services, you must bring the Original invoice.</Text></li>
        <li><Text type="body1">Exchanging and returning can be done within seven (7) days from the date of purchase if the item is in the same condition as the original purchase and wrapped in the original packaging with all its accessories.</Text></li>
        <li><Text type="body1">The customer shall bear the cost of shipping to the location specified by the service center.</Text></li>
        <li><Text type="body1">Receiving a shipment by customer from the shipping company it means that the shipment box is in good condition and has not been opened before.</Text></li>
        <li><Text type="body1">In case of a hardware defect within 7 days from the date of purchase, Camel Step shall bear the shipping and replacement costs of the product with a new product provided that the defective product is in the original condition at the time of purchase. If a similar product is not available, the customer is entitled to order another product or use the value of the defective product to purchase alternative product or refund the purchase amount.</Text></li>
      </ol>
      <Text type="headline3">Installation and delivery:</Text>
      <ol>
        <li><Text type="body1">In some cases that requires the attendance of the service team for installation and so on, this is subject to a schedule to be specified within (3) working days or as agreed upon sale. Or delivering machines of certain sizes, the customer must prepare certain requirements before the installation team leave the company.</Text></li>
        <li><Text type="body1">If the product requires preparation of installing such as electrical, gas, water and other installations supply or a place of entry or passage of machines of certain sizes, the customer must do so before the installation team exits Camel Step company.</Text></li>
        <li><Text type="body1">Conducting items with weights exceeding (100) kg to be delivered for the ground floor only.</Text></li>
        <li><Text type="body1">The customer should ensure that the location is ready for the installation with a person responsible for receiving and completing all pre-sent installation requirements.</Text></li>
        <li><Text type="body1">If our team arrives at the customer&apos;s premises and it is found that the place is unprepared or there is no person responsible for the receipt, the customer will have to contact the service team to set another date for the delivery and pays installation the fees.</Text></li>
        <li><Text type="body1">The service representative will communicate with the customer before the delivery date to confirm the appointment.</Text></li>
        <li><Text type="body1">The customer has the right to receive his purchases within a maximum period of (15) days from the date of purchase.</Text></li>
      </ol>
      <Text type="headline3">Data Protection</Text>
      <ol>
        <li><Text type="body1">Right to be informed: This includes informing the data subject of the legal justification for collecting their personal data, the purpose of the collection, the identity and reference address of the data collector, the entities to which the personal data may be disclosed, and their capacity; whether the personal data will be transferred, disclosed, or processed outside the Kingdom, the potential risks and consequences of not completing the data collection process, and the rights of the data subject. </Text></li>
        <li><Text type="body1">Right to access personal data: This includes requesting a copy of their personal data from the controller in a clear and readable format. Right to request personal data correction: This includes requesting the correction, completion, or updating of personal data held by the controller. </Text></li>
        <li><Text type="body1">Right to request personal data correction: This includes requesting the correction, completion, or updating of personal data held by the controller. </Text></li>
        <li><Text type="body1">Right to request the destruction of personal data: The data subject has the right to request that the controller destroy any of their personal data that is no longer needed. </Text></li>
        <li><Text type="body1">Right to withdraw consent to processing personal data: The data subject may withdraw consent to process their personal data at any time, except in cases stipulated by the Personal Data Protection Law and its implementing regulations.</Text></li>
      </ol>
    </>
  );

  const content_ar = (
    <>
      <Text type="headline2">السياسات والخصوصية</Text>
      <Text type="headline3">سياسة الجودة:</Text>
      <Text type="body1">في خطوة جمل نعي قيمة التطور المستمر في بيئة العمل وهوية المنظمة، حيث نحرص على توفير بيئة عمل محفزة وصحية كأهم أولوياتنا لضمان إرضاء عملائنا وتجاوز توقعاتهم من خلال العمل على أهداف الشركة ضمن سياساتها المعرفة، جاعلين نصب أعيننا كافة اللوائح المحلية والدولية.</Text>
      <Text type="headline3">سياسة الخصوصية:</Text>
      <Text type="body1">في خطوة جمل يمكن لأي مستخدم أن يزور موقعنا تحت هوية مجهولة، وفي حالة التسجيل او الاشتراك أو الشراء في موقعنا فإننا نطبق مجموعة من معايير الأمان وتتم معالجة جميع التعاملات عبر مزود خدمة البوابات الآمنة، التي ستساعدنا على تقديم خدمة أفضل لأجلك، بالإضافة إلى إن جميع معلومات بطاقات الائتمان التي تمدنا بها يتم تشفيرها بأفضل وسائل الأمان ولا يتم حفظها بخوادم الموقع او قاعدة البيانات.</Text>
      <Text type="headline3">سياسة الطلب :</Text>
      <Text type="body1">يحق للعميل إلغاء الطلب واسترجاع مبلغ الشراء فقط قبل الشحن، حيث يتم استرجاع المبلغ في مدة أقصاها 20 يوم عمل.</Text>
      <Text type="headline3">سياسة الشحن والتوصيل :</Text>
      <ol>
        <li>
          <Text type="body1">التوصيل داخل المملكة العربية السعودية خلال 3 – 5 أيام عمل.</Text>
        </li>
        <li>
          <Text type="body1">التوصيل لدول الخليج خلال 5 – 10 أيام عمل.</Text>
        </li>
        <li>
          <Text type="body1">لا تتحمل شركة خطوة جمل مسؤولية التلف الناتج عن الشحن.</Text>
        </li>
        <li>
          <Text type="body1">أي تأخر في تسليم الشحنة بسبب الناقل يعتبر خارج مسؤولية شركة خطوة جمل.</Text>
        </li>
        <li>
          <Text type="body1">إذا تم ارجاع الطلب من قبل الناقل لعدم رد العميل أو التأجيل المتكرر أو خطأ في العنوان المدخل سيتحمل العميل قيمة الشحن</Text>
        </li>
        <li>
          <Text type="body1">التزام العميل: عند إتمام أي طلب شحن خارج المملكة العربية السعودية يقوم العميل بإقرار للمسؤولية الكاملة بتحمل أي رسوم جمركية أو ضرائب أو أي رسوم مفروضة من قبل مسؤولي الجمارك حسب القوانين لتلك الدولة. هذه الرسوم لن تكون مضافة إلى سعر المنتج أو رسوم الشحن المعروضة أثناء إتمام الطلب.</Text>
        </li>
        <li>
          <Text type="body1">دفع الرسوم الجمركية: دفع أي رسوم جمركية مطلوبة لاستيراد المنتجات في الوقت المناسب هي مسؤولية العميل.  الفشل في دفع تلك الرسوم قد يؤدي إلى تأخر وصول الطلب أو فرض رسوم إضافية أو عودة الشحنة إلى شركة خطوة جمل. في حال عودة الشحنة إلى شركة خطوة جمل بسبب تجاهل العميل دفع الرسوم الجمركية أو رسوم الشحن بعد وصولها للوجهة المطلوبة فإنه يحق لشركة خطوة جمل مطالبة العميل بتكلفة الشحن وتكلفة إرجاع الشحنة.</Text>
        </li>
      </ol>
      <Text type="headline3">سياسة استلام الطلب من الفروع :</Text>
      <Text type="body1">يكون الطلب ملغي عند مضي 5 أيام عمل من موعد تسليم الطلب في حال لم يتم إستلامه.</Text>
      <Text type="body1">يحق لشركة خطوة جمل إلغاء تنفيذ خدمة الاستلام من الفرع والعمل على إيصال الشحنة عبر أحد شركات الشحن مع إشعار العميل بذلك عند حدوث أي طارئ خارج الإرادة.  </Text>
      <Text type="body1">في حال تعذر العميل عن استلام الشحنة يعتبر الطلب ملغي و يتم استرجاع المبلغ المستحق كاملا كرصيد في حساب العميل بالموقع أو في حال رغبة العميل باسترداد</Text>
      <Text type="body1">المبلغ لحساب البطاقة المستخدمة في عملية الشراء يجب عليه التواصل معنا لنقوم بذلك عبر الايميل support@camelstep.com</Text>
      <Text type="body1">الخدمة متاحة خلال أيام العمل من الأحد إلى الخميس.</Text>
      <Text type="body1">في حال تعذر العميل عن استلام الشحنة يعتبر الطلب ملغى ويتم استرجاع المبلغ المستحق كاملا كرصيد في حساب العميل بالموقع أوفي حال رغبة العميل باسترداد المبلغ لحساب البطاقة المستخدمة في عملیة الشراء یجب عليه التواصل معنا لنقوم بذلك عبر الواتساب: 966920000689 الخدمة متاحة خلال أيام العمل من الأحد إلى الخميس</Text>
      <Text type="headline3">سياسة برامج التعليم :</Text>
      <Text type="body1">يحق للمتدرب إلغاء وإسترجاع مبلغ برنامج التعليم قبل 3 أيام عمل من تاريخ بداية البرنامج مع إثبات الشراء.</Text>
      <Text type="body1">يحق للمتدرب إعادة جدولة تاريخ حضور البرنامج التعليمي بما يتناسب مع جدولة التواريخ المتاحة لبرامج التعليم من قبل المدربين قبل 3 أيام عمل من تاريخ بداية البرنامج مع</Text>
      <Text type="body1">إثبات الشراء، ولإعادة الجدولة يرجى التواصل عبر الواتساب 966920000689 .</Text>
      <Text type="body1">قد يتم إشعاركم بإعادة جدولة تاريخ ووقت برنامجكم التعليمي قبل 3 أيام عمل في حال لم يكتمل عدد المقاعد الكافي لبدء البرنامج التعليمي.</Text>
      <Text type="headline3">سياسة الضمان : </Text>
      <Text type="body1">تشمل السياسة البنود الأساسية لضمان المنتجات والأدوات الكهربائية التي تم شراؤها من شركة خطوة جمل لمدة سنتين، والتي تكون الشركة وكيلة لمنتجاتها، حيث تعرف حدود السياسة والأحكام الأساسية للحصول على الضمان وشروط الإرجاع والاستبدال. </Text>
      <Text type="headline3">بنود وأحكام سياسة الضمان: </Text>
      <ol>
        <li><Text type="body1">للاستفادة من الضمان لابد التأكد من استمرارية فترة الضمان بالرجوع لتاريخ الشراء المذكور في الفاتورة الأصلية وأن لا يكون قد تم فك الجهاز ومحاولة إصلاحه خارج مركز الخدمة المعتمد. </Text></li>
        <li><Text type="body1">يغطي الضمان خدمة إصلاح أو استبدال قطع الغيار مجاناً في حال وجود عيب مصعني. </Text></li>
        <li>
          <Text type="body1">يستثنى من خدمة الضمان الأعطال الناتجة عن: </Text>
          <ul>
            <li><Text type="body1">أ- إساءة استخدام المنتج بخلاف ما صنع له والهدف المصنع له، مثل: (الكسور أو الضربات، أو تعرض الجهاز للرطوبة العالية أو الحرارة العالية، تشغيل سخانات الماء دون تعبئتها بالماء، سوء النقل او التخزين، تمرير تيار مخالف لقدرة وتحمل الألة الكهربائية حتى وإن لم يتم تشغيلها). </Text></li>
            <li><Text type="body1">ب- العبث ومحاولة الإصلاح خارج مركز الخدمة المعتمد مثل: (محاولة استخدام قطع غيار غير معتمدة، العبث بالمكونات الداخلية). </Text></li>
            <li><Text type="body1">ت- عدم التقيد بتعليمات الاستخدام في دليل المنتج أو التعليمات المشار إليها في الصفحات الإلكترونية عند تشغيل واستخدام الجهاز، مثل: (التحميل الزائد على طاقة الجهاز ،التنظيف الخاطئ للجهاز، مخالفة شروط الصيانة الدورية أو تعليمات الشركة الصانعة) </Text></li>
          </ul>
        </li>
        <li>
          <Text type="body1">لا يشمل الضمان أي من التالي: </Text>
          <ul>
            <li><Text type="body1">أ- الأدوات اليدوية مثل الطواحين، وأدوات التقطير.</Text></li>
            <li><Text type="body1">ب- القطع الاستهلاكية كالتروس، أو الفلاتر، أو الربلات، أو نحو ذلك.</Text></li>
            <li><Text type="body1">ت- الإكسسوار أو الأجزاء الخارجية كالهيكل الخارجي، أو المقابض، أو نحو ذلك.</Text></li>
            <li><Text type="body1">ث- أي قطع أو أجزاء قام العميل بإضافتها.</Text></li>
          </ul>
        </li>
        <li><Text type="body1">يستغرق زمن الإصلاح (15) يوماً كحد أقصى للأعطال المشمولة بالضمان تبدأ من تاريخ صدور سند أمر الإصلاح من مركز الخدمة بشركة خطوة جمل (غير شامل الوقت اللازم لطلب القطع الغير متوفرة، و وقت شحن الجهاز من و الى العميل). </Text></li>
        <li><Text type="body1">في حال عدم توفر قطع الغيار لإتمام عملية الصيانة فإن طلب القطع من المصنع قد يستغرق مدة أقصاها ،(14) يوماً من تاريخ طلب القطع والموافقة عليها. وبالتالي فإن زمن الإصلاح المتفق عليه يتغير بذلك. </Text></li>
        <li><Text type="body1">تمديد مدة ضمان المنتج بما يعادل مدة التأخير في توفير قطعة الغيار أو البدء في أعمال الصيانة أو الإنتهاء منها . </Text></li>
        <li><Text type="body1">حال وجود عيب مصنعي تم إصلاحه مرتين ولم تُحل المشكلة فإنه يتم استبدال المنتج بمنتج مماثل. </Text></li>
        <li><Text type="body1">على العميل التأكد من سلامة الجهاز قبل استلامه من مركز الخدمة ويحق له طلب تشغيلها قبل الاستلام. </Text></li>
        <li><Text type="body1">يحق للعميل الحصول على فترة ضمان مدتها شهر على الأجزاء التي تمت صيانتها فقط في حال إنتهاء الضمان للمنتج. </Text></li>
        <li><Text type="body1">يحق للعميل الحصول على جهاز مؤقت من نفس الفئة أو ما يعادل (سعر شراء الجهاز الكهربائي ÷ 400 ) عن كل يوم تأخير . </Text></li>
        <li><Text type="body1">يحق للعميل الذي يتواجد خارج المدينة التي بها مركز الخدمة، طلب الحصول على بوليصة شحن من مقر اقامته الى مركز الخدمة شريطة أن يكون الضمان ساري وأن يشمل الضمان العطل الموجود بالمكينة . </Text></li>
        <li><Text type="body1">يلتزم مركز الخدمة بتقديم خدمة الصيانة لكافة منتجات شركة خطوة جمل حسب الاسعار الخاصة بكل منتج حتى بعد انتهاء الضمان. </Text></li>
        <li><Text type="body1">يمكن للعميل الذي يملك جهاز غير مغطى بخدمة الضمان أن يقوم بطلب فتح تذكرة صيانة للكشف على الجهاز في مركز الخدمة بمبلغ وقدره 150 ريال ثم الموافقة على تكاليف الصيانة وقطع الغيار.</Text></li>
        <li><Text type="body1">على العميل استلام جهازة في التاريخ المحدد بكرت الاصلاح او الرسالة المرسلة عبر الايميل أو الرسائل النصية بحيث يتم إخطار العميل ثلاث مرات خلال شهر . وفي حال عدم استلام العميل للجهاز، فإن مركز الخدمة غير مسؤول عن الجهاز وبإمكانه التصرف بعدم تسليمه. </Text></li>
        <li><Text type="body1">المنتجات التي توفرها شركة خطوة جمل من وكلاء آخرين محليين يتم صيانتها من قبل وكيل المنتج. </Text></li>
        <li><Text type="body1">بإمكان العميل التواصل إما لفتح أو تقديم طلب صيانة دورية أو إصلاح عطل طارئ من خلال الواتساب 966920000689</Text></li>
      </ol>
      <Text type="headline3">بنود وأحكام سياسة الاستبدال والاسترجاع:</Text>
      <ol>
        <li><Text type="body1">يشمل الاستبدال و الاسترجاع جميع الأصناف عدا الفلاتر و أكياس البن والمشروبات. </Text></li>
        <li><Text type="body1">للاستفادة من خدمات الاستبدال والاسترجاع يجب إحضار الفاتورة الأصلية .</Text></li>
        <li><Text type="body1">يمكن الاستبدال والاسترجاع خلال سبعة(7) أيام من تاريخ الشراء إذا كان الصنف بنفس حالته الأصلية عند الشراء ومغلفا بالغلاف الأصلي مع كافة ملحقاته</Text></li>
        <li><Text type="body1">يمكن للعميل الاسترجاع والاستبدال في كافة قنوات البيع الخاصة بشركة خطوة جمل للتجارة سوآءا معارض التجزئة أو المنصات الإلكترونية على أن يتحمل العميل تكاليف الشحن إلى المقر المحدد من قبل مركز الخدمة. </Text></li>
        <li><Text type="body1">لا يحق للعميل استرجاع أو استبدال المنتجات حال شرائها أثناء العروض الترويجية ، وفي حال وجود مشكلة في المنتج أو الجهاز تستدعي الاستبدال، فإن القيمة المتبعة للمنتج أو الجهاز هي قيمة العرض الترويجي .</Text></li>
        <li><Text type="body1">استلام العميل الشحنة من شركة الشحن يعتبر إقرارا من العميل أن الشحنة (الكرتون) سليمة وبحالة جيدة ولم يتم فتحها  قبل الاستلام. </Text></li>
        <li><Text type="body1">لا يحق للعميل استرجاع أو استبدال المنتج الذي تم شراؤه من المنصات الالكترونية الخاصة بخطوة جمل في فروع التجزئة.</Text></li>
        <li><Text type="body1">في حال وجود عيب مصنعي بالأجهزة الكهربائية في غضون 7 أيام من تاريخ الشراء، فإن شركة خطوة جمل تتحمل تكاليف الشحن واستبدال المنتج بمنتج جديد شريطة أن يكون المنتج المعيب بنفس حالته الأصلية عند الشراء. وفي حال عدم توفر منتج مماثل فإنه يحق للعميل طلب منتج آخر أو استخدام قيمة المنتج المعيب لشراء منتج آخر أو استرداد مبلغ الشراء. </Text></li>
      </ol>
      <Text type="headline3">التوصيل والتركيب :</Text>
      <ol>
        <li><Text type="body1">في بعض الحالات التي تستدعي خروج فريق الخدمة للتركيب ونحوه فإن ذلك يخضع لجدول زمني يتم تحديده خلال (3) أيام عمل او حسب الاتفاق عند البيع</Text></li>
        <li><Text type="body1">في حال كان المنتج يتطلب تهيئة مكان التركيب كالتمديدات الكهربائية وتمديدات الغاز والماء وخلافه أو تهيئة مكان للدخول او مرور الآلات بأحجام معينة يتوجب على العميل تنفيذ ذلك قبل خروج فريق التركيب من شركة خطوة جمل.</Text></li>
        <li><Text type="body1">تسليم أصناف يزيد وزنها عن (100) كيلو جرام للعميل في الطابق الأرضي فقط.</Text></li>
        <li>
          <Text type="body1">
            على العميل التأكد من تهيئة مكان الجهاز بشكل كامل مع وجود شخص مسؤول للاستلام وإتمام كافة متطلبات التركيب المرسلة مسبقا.
          </Text>
        </li>
        <li><Text type="body1">في حال التوصيل الى مقر العميل وتبين ان المكان غير مهيأ او لا يوجد شخص مسؤول للاستلام فإنه يتوجب على العميل التنسيق مع فريق الخدمة لتحديد موعد آخر للتوصيل ودفع الرسوم المترتبة على ذلك ولن يكون التوصيل مجاني للمرات التالية حتى وان كان مجانى في المرة الأولى. </Text></li>
        <li><Text type="body1">يقوم ممثل الخدمة بالتواصل مع العميل قبل موعد التوصيل لتأكيد الموعد وفي حالة عدم تجاوب العميل فإنه يحق لممثل شركة خطوة جمل إلغاء الموعد وجدولة موعد جديد حسب المتاح في الجدول الزمني لمركز الخدمة واخطار العميل بذلك.</Text></li>
        <li><Text type="body1">يحق للعميل استلام مشترياته خلال مدة أقصاها (15) يوم من تاريخ الشراء وفي حال عدم الاستلام فإنه يحق لشركة خطوة جمل المطالبة بتكاليف التخزين التي تجاوزت المدة المحددة ولم يتم إستلامها .</Text></li>
      </ol>
      <Text type="headline3">حماية البيانات</Text>
      <ol>
        <li><Text type="body1">الحق في العلم: يشمل ذلك إحاطته علماً بالمسوغ النظامي لجمع بياناته الشخصية والغرض من جمعها، وهوية من يجمع البيانات الشخصية وعنوان مرجعه، والجهة أو الجهات التي سيُجرى إفصاح البيانات الشخصية لها وصفتها وما إذا كانت البيانات الشخصية ستنقل أو سيفصح عنها أو ستعالج خارج المملكة، والآثار والأخطار المحتملة التي تترتب على عدم إتمام إجراء الجمع، بالإضافة إلى حقوق صاحب البيانات الشخصية</Text></li>
        <li><Text type="body1">الحق في الوصول إلى البيانات الشخصية: ويشمل ذلك طلب نسخة من بياناته الشخصية المتوفرة لدى جهة التحكم بصيغة مقروءة وواضحة</Text></li>
        <li><Text type="body1">الحق في طلب تصحيح البيانات الشخصية: ويشمل ذلك طلب تصحيح البيانات الشخصية المتوفرة لدى جهة التحكم، أو إتمامها، أو تحديثها</Text></li>
        <li><Text type="body1">الحق في طلب إتلاف البيانات الشخصية: يحق لصاحب البيانات الشخصية طلب إتلاف بياناته الشخصية المتوفرة لدى جهة التحكم مما انتهت الحاجةإليهمنها</Text></li>
        <li><Text type="body1">الحق في الرجوع عن الموافقة على معالجة البيانات الشخصية: يمكن لصاحب البيانات الشخصية الرجوع عن الموافقة على معالجة بياناته الشخصية في جميع الأحوال وفي أي وقت فيما عدا الأحوال المنصوص عليها في نظام حماية البيانات الشخصية ولائحته التنفيذية.</Text></li>
      </ol>
    </>
  );

  return (
    <MainLayout>
      <div className="static_page policy-container">
        {i18n.language === 'en' ? content_en : content_ar}
      </div>
    </MainLayout>
  );
};
