/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDigitInput from 'react-digit-input';

import { Text } from '../../components/Text/Text';
import { Button } from '../../components/common/Button/Button';
import { Alert } from '../../components/common/Alert/Alert';
import { CLOUDFLARE_TURNSTILE_SITE_KEY } from '../../config';

export const VerificationCodeForm = ({
  code,
  onChangeCode,
  onSubmitOtpCode,
  status,
  handleResendCode,
  phoneNumber,
  setCaptchaStatus,
}) => {
  const { i18n, t } = useTranslation('application');
  const currentLanguage = i18n.language;
  const [seconds, setSeconds] = React.useState(60);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const resendCodeInterval = React.useRef();
  const firstDigitRef = useRef();
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: code,
    onChange: onChangeCode,
  });

  const handleResend = () => {
    if (seconds === 0) {
      handleResendCode(phoneNumber);
      setSeconds(60);
      clearInterval(resendCodeInterval.current);
      /* eslint-disable immutable/no-mutation */
      resendCodeInterval.current = setInterval(() => {
        setSeconds((currentSeconds) => currentSeconds - 1);
      }, 1000);
    }
  };

  useEffect(() => {
    /* eslint-disable immutable/no-mutation */
    resendCodeInterval.current = setInterval(() => {
      setSeconds((currentSeconds) => currentSeconds - 1);
    }, 1000);

    return () => clearInterval(resendCodeInterval.current);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(resendCodeInterval.current);
    }
  }, [seconds]);

  useEffect(() => {
    if (firstDigitRef.current) {
      firstDigitRef.current.focus();
    }
  }, []);

  // TODO: Validate captcha domain
  useEffect(() => {
    const onloadTurnstileCallback = () => {
      if (window.turnstile) {
        window.turnstile.remove('#turnstile-container');
      }
      window.turnstile.render('#turnstile-container', {
        sitekey: CLOUDFLARE_TURNSTILE_SITE_KEY,
        theme: 'light',
        language: currentLanguage === 'ar' ? 'ar' : 'en',
        callback(token) {
          setCaptchaStatus(token);
        },
      });
    };

    if (window.turnstile) {
      onloadTurnstileCallback();
    } else {
      window.onloadTurnstileCallback = onloadTurnstileCallback;
    }

    return () => {
      delete window.onloadTurnstileCallback;
    };
  }, [resetCaptcha]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setResetCaptcha((prev) => !prev);
    onSubmitOtpCode(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      if (firstDigitRef.current) {
        firstDigitRef.current.value = '';
        onChangeCode('');
        firstDigitRef.current.focus();
      }
    } else if (e.key === 'ArrowLeft') {
      // Move focus to the previous input
      e.preventDefault();
      const prevInput = e.target.previousElementSibling;
      if (prevInput && prevInput.tagName === 'INPUT') {
        prevInput.focus();
      }
    } else if (e.key === 'ArrowRight') {
      // Move focus to the next input
      e.preventDefault();
      const nextInput = e.target.nextElementSibling;
      if (nextInput && nextInput.tagName === 'INPUT') {
        nextInput.focus();
      }
    }
  };

  return (
    <div className="verification-code-form">
      <Text type="headline2" className="login__header">
        {t('login.numberVerificationHeader')}
      </Text>
      <form onSubmit={handleSubmit} dir="ltr">
        <div className="login__form-element login__form-container digits ">
          <input
            className="login__input login__digit"
            type="text"
            name="digit1"
            inputMode="numeric"
            {...digits[0]}
            ref={firstDigitRef}
          />
          <input
            className="login__input login__digit"
            type="text"
            name="digit2"
            inputMode="numeric"
            {...digits[1]}
            onKeyDown={handleKeyDown}
          />
          <input
            className="login__input login__digit"
            type="text"
            name="digit3"
            inputMode="numeric"
            {...digits[2]}
          />
          <input
            className="login__input login__digit"
            type="text"
            name="digit4"
            inputMode="numeric"
            {...digits[3]}
          />
          <input
            className="login__input login__digit"
            type="text"
            name="digit5"
            inputMode="numeric"
            {...digits[4]}
          />
          <input
            className="login__input login__digit"
            type="text"
            name="digit6"
            inputMode="numeric"
            {...digits[5]}
          />
        </div>
        <div className="turnstile-wrapper">
          <div id="turnstile-container" />
        </div>
        <div className="login__form-element">
          <Text type="body1">
            {t('login.notReceiveVerificationCode')}
            <br />
          </Text>
          <Button className="verification-resend" onClick={handleResend} type="naked">
            {t('forms.otp.resend')}
          </Button>
          <Text type="body1">
            {t('forms.otp.in')}
            {' '}
            <span>{seconds}</span>
            {' '}
            {t('forms.otp.seconds')}
          </Text>
        </div>
        <div className="login__form-element">
          <Button buttonType="submit" text={t('login.confirmCode')} type="primary" disabled={status?.type === 'success'} />
        </div>
      </form>
      {status && (
      <Alert type={status.type}>{status.message}</Alert>
      )}
    </div>
  );
};
