import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MainLayout } from '../../components/Layout/MainLayout';
import './Locations.scss';
import LocationCover from '../../assets/images/locationHeroImage.webp';
import LocationCover2 from '../../assets/images/Locations cover 2.png';
import { branchesCountriesOptions, branchesLocation } from '../../utils/branchesLocation';
import { BranchCardLocationPage } from './BranchCardLocationPage';
import { OurBranchesSection } from '../../components/OurBranchesSection/OurBranchesSection';
import { locationsSchemaData } from '../../schemaData/locationsSchemaData';

export const Locations = () => {
  const { t, i18n } = useTranslation('application');
  const currentLanguage = i18n.language;
  const branchesCountries = branchesCountriesOptions.slice(1);
  const defaultCountry = branchesCountriesOptions.find((country) => country.value === 'saudia');

  const [selectedCountryOption, setSelectedCountryOption] = useState(defaultCountry);

  const handleBranchesCountrySelect = (country) => {
    setSelectedCountryOption(country);
  };

  return (
    <>
      <Helmet>
        <title>{t('seo.locations.title')}</title>
        <meta name="description" content={t('seo.locations.description')} />
        <script type="application/ld+json">
          {JSON.stringify(locationsSchemaData)}
        </script>
      </Helmet>
      <MainLayout>
        <div className="locations-hero-section">
          <div className="hero-label-container">
            <div className="hero-label">
              <h1 className="hero-label-title">{t('locationsPage.heroTitle')}</h1>
              <p className="hero-label-subtitle">{t('locationsPage.heroContent')}</p>
            </div>
          </div>
          <div className="hero-section-image-container">
            <img className="hero-section-image" src={LocationCover} alt="locations" />
          </div>
        </div>
        <div className="branch-selector-container">
          <p className="text-heading">{t('locationsPage.branchesSectionTitle')}</p>
          <div className="branch-countries">
            {
            branchesCountries.map((country, index) => (
              <button
                type="button"
                key={index}
                value={country.value}
                className={country.value === selectedCountryOption.value ? 'clicked' : ''}
                onClick={() => handleBranchesCountrySelect(country)}
              >
                {country.translationName[currentLanguage]}
              </button>
            ))
          }
          </div>
        </div>
        {selectedCountryOption && <p className="text-heading cards-container-heading">{selectedCountryOption.translationName[currentLanguage]}</p>}
        <div className="branch-cards-section">
          {
          branchesLocation.filter((branch) => branch.country === selectedCountryOption.value).map((branch, index) => (
            <BranchCardLocationPage key={index} branch={branch} />
          ))
        }
        </div>
        <div className="locations-lower-banner">
          <div className="lower-banner-label-container">
            <div className="lower-banner-label">
              <h1 className="lower-banner-label-title">{t('locationsPage.lowerBannerTitle')}</h1>
              <p className="lower-banner-label-subtitle">{t('locationsPage.lowerBannerContent')}</p>
            </div>
          </div>
          <div className="lower-banner-image-container">
            <img className="lower-banner-image" src={LocationCover2} alt="locations" />
          </div>
        </div>
        <OurBranchesSection />
      </MainLayout>
    </>
  );
};
